<template>
  <div class="setup">
    <div class="setup_heard">
      <div>
        <div class="setud-div">
          <p style="font-weight: bold;">通知渠道</p>
          <div class="setud-div-01">
            <p>是否开启</p>
            <p>操作</p>
          </div>
        </div>
        <div>
          <!-- <span style="color:#333333;margin-left:50px;">通知渠道</span> -->
          <div v-for="(item, index) in templateArr" :key="index" class="setup_content">
            <div style="display:flex">
              <img style="margin-left:20px;" :src="item.iopon" alt />
              <div class="setup_content_left">
                <span class="rightspan">{{ item.message }}</span>
                <span class="leftspan" @click="editbut(index)"
                  v-if="hasPerm(['setting.notify_service.update'])">立即申请</span>
                <p class="bottomp">{{ item.botttext }}</p>
              </div>
            </div>

            <div class="div-kai">
              <el-switch @change="kaibtn($event, item.type)" v-model="item.value" :active-value="1"
                :inactive-value="0"></el-switch>
              <el-button @click="editbut(index)" v-if="hasPerm(item.permission)">编辑</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';

export default {
  data() {
    return {
      arrange: [
        {
          message: "短信通知",
          botttext:
            "提供专业、快速、低成本的短信平台发送和短信接口，满足各类企业需求",
          value: 0,
          iopon: require("@/assets/icon/group_17_back.svg"),
          type: "notify.sms.open",
          permission: ['setting.sms_notify.update'],
        },
        {
          message: "邮件通知",
          botttext:
            "提供专业、快速、低成本的短信平台发送和短信接口，满足各类企业需求",
          value: 0,
          iopon: require("@/assets/icon/mail.svg"),
          type: "notify.email.open",
          permission: ['setting.email_notify.update'],
        }
      ],
      templateArr: []
    };
  },
  created() {
    this.keyninit();
  },
  methods: {
    kaibtn(value, type) {
      // 获取所有的状态
      const result = this.templateArr.reduce((pre, item) => {
        pre[item.type] = item.value
        return pre;
      }, {})
      // 修改状态
      this.$put(this.$apis.switchNotifyApi, result).then(res => {
        if (res.code == 200) {
          Message.success("编辑消息通知服务状态成功!");
          // 刷新状态
          this.keyninit();
        } else {
          Message.success(res.message);
        }
      })
    },
    // 初始化获取第三方按钮显示
    keyninit() {
      this.$get(this.$apis.switchNotifyApi).then(res => {
        for (const key in res.data) {
          let index = this.arrange.findIndex(item => item.type == key);
          this.$nextTick(() => {
            this.$set(this.arrange[index], 'value', res.data[key])
            this.$set(this, 'templateArr', this.arrange)
          })
        }
      });
    },
    editbut(index) {
      if (index == 0) {
        this.$router.push("/setup/notifyinde/notifyinform");
      } else if (index == 1) {
        this.$router.push("/setup/emilindex/milbt");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.setup {

  // position: relative;
  // .backdrop{
  //   width: 100%;
  //   height: 50px;
  //   background: #F9F9FC;
  //   position: absolute;
  // }
  .setup_heard {

    // display: flex;
    // justify-content: space-between;
    .setud-div {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      height: 50px;
      background: #f9f9fc;

      p {
        font-weight: 800;
        margin-left: 20px;
      }

      .setud-div-01 {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 400px;

        p {
          font-weight: bold;
          margin-right: 60px;
        }
      }

      p {
        // margin-left: 20px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0px;
      }
    }

    width: 100%;
    border-radius: 4px;
    margin-bottom: 20px;
    background: #fff;
    padding: 20px 20px 500px 20px;
    box-sizing: border-box;

    .setup_content {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      height: 70px;
      border-bottom: 1px solid #f2f2f2;

      .div-kai {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        width: 370px;

        .el-switch {
          margin-top: -25px;
        }

        .el-button {
          width: 88px;
          height: 38px;
          margin-top: -30px;
          margin-right: 30px;
        }
      }

      .setup_content_left {
        width: 500px;
        margin-left: 56px;
        position: relative;

        .leftspan {
          position: absolute;
          top: 2px;
          left: 96px;
          margin-left: 24px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: var(--fontColor);
          cursor: pointer;
        }

        .rightspan {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }

        .bottomp {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          width: 800px;
          margin-top: 6px;
        }
      }

      img {
        width: 49px;
        height: 53px;
      }
    }

    p {
      margin-top: 27px;
    }

    .setup_edit {
      width: 28px;
      height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-top: 50px;
      cursor: pointer;
    }
  }
}
</style>